import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="New Hope Pet Center" keywords={[`New Hope Pet Center`, `New Hope`, `Pet Center`, `Pet Grooming`, `Pet Supplies`]} />
    <div className="mw7 center">
      <div className="tc mv5 ph3">
        <h1 className="f3 f2-ns lh-title">Page Not Found</h1>
        
        <p className="f5 lh-title">
        Call <a href="tel:2158622778" className="link no-underline bb green fw8">(215) 862-2778</a> to make an appointment or ask any questions
        </p>
      </div>


      <div className="tc pb4">
        <b className="db pa3">Retail Store Hours</b>
        <div className="pa2">Weekdays 9:00 – 6:00</div>
        <div className="pa2">Saturday 10:00 – 5:00</div>
        <div className="pa2">Sunday 11:00 – 4:00</div>
        <b className="db pa3">Grooming Hours: by Appointment</b>
        <div className="pa2">Monday – Friday</div>
        <div className="pa2">Beginning at 8:00a.m.</div>
      </div>
      
    </div>
  </Layout>
)

export default NotFoundPage
